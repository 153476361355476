.navbar {
    background: #fffefc !important;
}

.navbar .toolbar {
    padding-left: 0;
}

.toolbar .img-link {
    height: 75px;
}

.navbar a {
    margin-right: auto;
}

.navbar .link {
    margin-right: 20px;
    color: #204e79;
    font-weight: bold;
}

.navbar .link:hover {
    color: #a79a3b;
}

.drawer-link {
    margin: 0 15px !important;
    color: #204e79 !important;
    font-weight: bold !important;
}

.drawer-link:hover {
    color: #a79a3b !important;
}