.apply {
    height: 80vh;
    background-image: url('../../img/indoor_min.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.apply-container {
    color: #FFFEFC;
    font-weight: bold;
    text-align: center;
    max-height: 250px;
    /* line-height: 5rem; */
    width: 30%;
    padding: 40px;
    box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
    background-color: #FFFEFC;
    border-radius: 4px;
}

.apply-btn-container {
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.apply-btn-container button{
    max-width: 220px;
    text-align: center;
	padding: 15px 30px;
	font-weight: 700;
	letter-spacing: 1.54px;
	border-radius: 25px;
	border: none;
	background-color: #204e79;
    color: #FFFEFC;
	cursor: pointer;
    display: flex;
    align-items: center;
}

.apply-btn-container button:hover {
    color: #a79a3b;
}

.apply-btn-container a {
    color: #FFFEFC;
    text-decoration: none;
}

.apply-btn-container a:hover {
    color: #a79a3b;
}

@media (max-width: 900px) {
    .apply {
        padding: 125px 0px;
        height: 40vh;
    }
    .apply-container {
        width: 100%;
    }
}
