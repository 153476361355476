.team {
    padding: 125px 125px;
    background-color: #204e79;
}

.team-header{
    font-size: 3rem;
    color: #FFFEFC;
    text-align: center;
    margin-bottom: 75px;
}

#team-main {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.team .card-content {
    padding-bottom: 20px;
    text-align: center;
}

.team .card {
    width: 300px;
    color: #FFFEFC;
    margin-bottom: 50px;
    box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
}

.team .avatar-container {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team .card-experience {
    font-size: 1.10rem;
}

.team .card-social {
    display: flex;
    justify-content: space-evenly;
    font-size: 1rem;
}

.card-social a{
    color: #204e79;
}

.card-social a:hover{
    color: #a79a3b;
}

@media (max-width: 900px) {
    .team {
        padding: 125px 0;
    }

    .team-header {
        font-size: 2.5rem;
    }
}

@media (min-width: 1750px) {

    #team-main {
        width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }

    .offerings .cards {
        width: 1300px;
        margin-right: auto;
        margin-left: auto;
    }
}