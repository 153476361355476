.hero {
    height: 90vh;
    background-image: url('../../img/house1_min.webp');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}


.hero .tagline {
    color: #FFFEFC;
    text-align: left;
    width: 55%;
    padding: 25px;
    -webkit-backdrop-filter: blur(8px);  /* Safari 9+ */
    backdrop-filter: blur(8px); /* Chrome and Opera */
    box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
    background-color: rgb(228 228 228 / 15%); 
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.hero-title {
    margin-bottom: 10px;
    font-size: 3rem;
    font-weight: bold;
}

.hero-italic {
    font-style: italic;
    font-size: 2rem;
}

.slogan {
    margin-bottom: 7px;
}

.tagline span {
    color: #204e79;
}

.hero .buttonContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 20px 40px 0 0;
}

.hero .buttonContainer button {
    max-width: 220px;
    text-align: center;
	padding: 15px 30px;
	font-weight: 700;
	letter-spacing: 1.54px;
	border-radius: 25px;
	border: none;
	background-color: #FFFEFC;
	cursor: pointer;
    display: flex;
    align-items: center;
}

.hero button a {
    color: #204e79;
    margin: 0;
    font-size: 1.10rem;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: bold;
    line-height: 1.334;
    letter-spacing: 0em;
    text-decoration: none;
}

.hero button a:hover {
    color: #a79a3b;
}

@media (max-width: 900px) {
    .hero {
        height: 50vh;
        background-position-x: -100px;
    }

    .hero .tagline {
        width: 100%;
        text-align: center;
        padding: 25px 0;
    }

    .hero-title {
        font-size: 2.4rem;
    }

    .hero-italic {
        font-size: 1.3rem;
    }

    .hero .buttonContainer {
        margin-right: 0;
    }
}