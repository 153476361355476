.offerings {
    background-color: #FFFEFC;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #204e79;
}

.offerings .about {
    display: flex;
    justify-content: center;
}

.about-text {
    width: 65%;
    margin: 100px 0 100px 0 !important;
}

.about-text span {
    color: #a79a3b;
    font-weight: bold;
}

.offerings .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 75px;
}

.offerings .card {
    background-color: #204e79;
    padding: 20px;
    min-width: 275px;
    max-width: 375px;
    margin-bottom: 50px;
    box-shadow: 0px 10px 15px 10px rgb(0 0 0 / 15%);
}

@media (max-width: 900px) {
    .about-text {
        text-align: center;
    }

    .offerings .card {
        text-align: center;
    }
}


