html {
    scroll-behavior: smooth;
}

body{
    margin: 0;
    font-family: "Roboto","Helvetica","Arial", sans-serif;
}

.main {
    margin-top: 75px;
}

.footer {
    background-color: #a79a3b;
    padding-top: 50px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.footer tr {
    width: 300px;
}

/* .footer-mid{
    text-align: center;
} */
.footer-mid label {
    color: #204e79
}

.footer-right {
    text-align: center;
}

.footer-social {
    width: 200px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-evenly;
}

.footer-social a{
    color: #FFFEFC;
}

.footer-social a:hover{
    color: #204e79;
}

.copyright {
    background-color: #a79a3b;
    text-align: center;
    padding: 20px;
}

@media (max-width: 900px) {
    .footer {
        text-align: center;
    }

    .footer-mid {
        margin-top: 25px;
    }

    .footer-social {
        margin-top: 30px;
        width: 300px;
    }
}